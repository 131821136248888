import React, { useEffect, useState, useRef } from "react";

import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import Tab from "react-bootstrap/Tab";
import Button from "react-bootstrap/Button";
import Tabs from "react-bootstrap/Tabs";
import Auth from "../Auth";
import Menu from "./Menu";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import * as http from "../Api/Http";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import Spinner from 'react-bootstrap/Spinner';
import ToolkitProvider, {
    Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';


const { SearchBar, ClearSearchButton } = Search;

const License = (props) => {
    const [validated, setValidated] = useState(false);
    const [alert, setAlert] = useState<JSX.Element>(<></>);
    const [name, setName] = useState("");
    const [lastname, setLastname] = useState("");
    const [company, setCompany] = useState("");
    const [domain, setDomain] = useState("");
    const [email, setEmail] = useState("");

    const [backupemail, setBackupemail] = useState("");
    const [country, setCountry] = useState("");
    const [state, setState] = useState("");
    const [show, setShow] = useState(false);
    const [license, setLicense] = useState("");
    const [isCopied, setIsCopied] = useState(false);

    let sendLicenseInfo = () => {
        let body = JSON.stringify({
            name: name,
            lastname: lastname,
            company: company,
            domain,
            email: email,
            backupemail: backupemail,
            country: country,
            state: state,
        });
        http.sendToServer(
            "POST",
            "/api/createlicense",
            null,
            body,
            (resp) => {
                resp
                    .json()
                    .then((js) => {
                        setShow(true);
                        setLicense(js.license);

                        setName("");
                        setLastname("");
                        setCompany("");
                        setDomain("");
                        setEmail("");

                        setBackupemail("");
                        setCountry("");
                        setState("");
                    })
                    .catch((error) => {
                        setAlert(
                            <Alert
                                variant="danger"
                                onClose={() => setAlert(<></>)}
                                dismissible
                            >
                                Error creating license: {error.message}
                            </Alert>
                        );
                    });
            },
            (resp) => {
                resp != null &&
                    resp.text().then((t) =>
                        setAlert(
                            <Alert
                                variant="danger"
                                onClose={() => setAlert(<></>)}
                                dismissible
                            >
                                Error creating mapping: {t}
                            </Alert>
                        )
                    );
            },
            (error) => {
                setAlert(
                    <Alert variant="danger" onClose={() => setAlert(<></>)} dismissible>
                        Error creating license.
                    </Alert>
                );
            }
        );
    };

    let handleSubmit = (event) => {
        if (form.current == null) {
            return false;
        }

        if (form.current.reportValidity() === false) {
            event.preventDefault();
            setValidated(true);
            return false;
        }
        event.preventDefault();
        setValidated(false);
        event.stopPropagation();
        sendLicenseInfo();
        return false;
    };

    let form = useRef<HTMLFormElement>(null);
    const copyText = async () => {
        try {
            await navigator.clipboard.writeText(license);

            // Set isCopied to true after successfully copying to the clipboard
            setIsCopied(true);

            // Optionally: Reset the isCopied state back to false after a certain duration
            setTimeout(() => setIsCopied(false), 2000); // Reset after 2 seconds
        } catch (err) {
            console.error("Failed to copy text: ", err);
        }
    };

    return (
        <div className="p-4">
            {alert}
            <Modal
                show={show}
                onHide={() => {
                    setLicense("");
                    setShow(false);
                }}
                dialogClassName="w90"
                style={{ maxWidth: "100%", width: "100%" }}
            >
                <Modal.Header closeButton>
                    <Modal.Title>License created</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>The license code:</div>
                    <Row className="my-3">
                        <Col
                            className="ml-3  p-2"
                            style={{
                                overflowX: "auto", // add a horizontal scrollbar if needed
                                maxWidth: "100%", // or another value to limit the width to its parent
                                fontSize: "0.8em",
                                border: "1px solid gray",
                            }}
                        >
                            {license}
                        </Col>
                        <Col xs="auto" className="py-2 mr-3">
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip id={`tooltip-top`}>
                                        {isCopied ? "Copied" : "Copy"}
                                    </Tooltip>
                                }
                            >
                                <i
                                    className="fa-solid fa-copy blue"
                                    onClick={copyText}
                                    style={{ fontSize: "1.6em" }}
                                ></i>
                            </OverlayTrigger>
                        </Col>
                    </Row>
                    Please copy the license code and send it to the customer.
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="dymium"
                        size="sm"
                        onClick={() => {
                            setLicense("");
                            setShow(false);
                        }}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            <Form onSubmit={handleSubmit} ref={form} noValidate validated={validated}>
                <Row>
                    <Col xs="auto">
                        <Form.Group className="mb-3 text-left" controlId="companyname">
                            <Form.Label>Customer company name:</Form.Label>
                            <Form.Control
                                size="sm"
                                type="text"
                                required
                                placeholder="Human readable name"
                                style={{ width: "40em" }}
                                pattern=".+"
                                value={company}
                                onChange={(e) => {
                                    setCompany(e.target.value);
                                }}
                            />
                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid">
                                Enter company name
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>

                    <Col xs="auto">
                        <Form.Group className="mb-3 text-left" controlId="domain">
                            <Form.Label>Domain:</Form.Label>
                            <Form.Control
                                size="sm"
                                type="text"
                                required
                                placeholder="company.com"
                                style={{ width: "40em" }}
                                pattern=".+"
                                value={domain}
                                onChange={(e) => {
                                    setDomain(e.target.value);
                                }}
                            />
                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid">
                                Enter customer domain
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col xs="auto">
                        <Form.Group className="mb-3 text-left" controlId="name">
                            <Form.Label>Contact First name:</Form.Label>
                            <Form.Control
                                size="sm"
                                type="text"
                                required
                                placeholder="First name"
                                style={{ width: "40em" }}
                                pattern=".+"
                                value={name}
                                onChange={(e) => {
                                    setName(e.target.value);
                                }}
                            />
                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid">
                                Enter first name
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col xs="auto">
                        <Form.Group className="mb-3 text-left" controlId="lastname">
                            <Form.Label>Contact Last name:</Form.Label>
                            <Form.Control
                                size="sm"
                                type="text"
                                required
                                placeholder="Last name"
                                style={{ width: "40em" }}
                                pattern=".+"
                                value={lastname}
                                onChange={(e) => {
                                    setLastname(e.target.value);
                                }}
                            />
                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid">
                                Enter last name
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col xs="auto">
                        <Form.Group className="mb-3 text-left" controlId="email">
                            <Form.Label>Contact email:</Form.Label>
                            <Form.Control
                                size="sm"
                                type="text"
                                required
                                placeholder="Contact name"
                                style={{ width: "40em" }}
                                pattern=".+"
                                value={email}
                                onChange={(e) => {
                                    setEmail(e.target.value);
                                }}
                            />
                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid">
                                Enter contact email
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col xs="auto">
                        <Form.Group className="mb-3 text-left" controlId="backupemail">
                            <Form.Label>Backup email:</Form.Label>
                            <Form.Control
                                size="sm"
                                type="text"
                                required
                                placeholder="Backup email"
                                style={{ width: "40em" }}
                                pattern=".+"
                                value={backupemail}
                                onChange={(e) => {
                                    setBackupemail(e.target.value);
                                }}
                            />
                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid">
                                Enter backup email
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col xs="auto">
                        <Form.Group className="mb-3 text-left" controlId="country">
                            <Form.Label>Country:</Form.Label>
                            <Form.Control
                                size="sm"
                                type="text"
                                required
                                placeholder="USA, Canada, etc."
                                style={{ width: "40em" }}
                                pattern=".+"
                                value={country}
                                onChange={(e) => {
                                    setCountry(e.target.value);
                                }}
                            />
                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid">
                                Enter country
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>

                    <Col xs="auto">
                        <Form.Group className="mb-3 text-left" controlId="state">
                            <Form.Label>State/Province:</Form.Label>
                            <Form.Control
                                size="sm"
                                type="text"
                                required
                                placeholder="CA, NY, etc."
                                style={{ width: "40em" }}
                                pattern=".+"
                                value={state}
                                onChange={(e) => {
                                    setState(e.target.value);
                                }}
                            />
                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid">
                                Enter state/province
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>
                <div className="text-left w-100">
                    <Button variant="dymium" size="sm" className="mt-4" type="submit">
                        Apply
                    </Button>
                </div>
            </Form>
        </div>
    );
};

const Licenses = (props) => {
    const [validated, setValidated] = useState(false);
    const [alert, setAlert] = useState<JSX.Element>(<></>);
    const [spinner, setSpinner] = useState(false);
    const [licenses, setLicenses] = useState([]);
    const [rememberedSelection, setRememberedSelection] = useState("");
    const [isCopied, setIsCopied] = useState(false);
    const [name, setName] = useState("");
    const [lastname, setLastname] = useState("");
    const [company, setCompany] = useState("");
    const [domain, setDomain] = useState("");
    const [email, setEmail] = useState("");

    const [backupemail, setBackupemail] = useState("");
    const [country, setCountry] = useState("");
    const [state, setState] = useState("");

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [license, setLicense] = useState("");
    let form = useRef<HTMLFormElement>(null);

    let updateActiveInfo = (id, active) => {
        setSpinner(true);
        let body = JSON.stringify({
            id,
            active

        });
        http.sendToServer(
            "POST",
            "/api/updateactive",
            null,
            body,
            (resp) => {
                resp
                    .json()
                    .then((js) => {
                        setSpinner(false);
                        getLicenseInfo()
                    })
                    .catch((error) => {
                        setSpinner(false);
                        setAlert(
                            <Alert
                                variant="danger"
                                onClose={() => setAlert(<></>)}
                                dismissible
                            >
                                Error updating record: {error.message}
                            </Alert>
                        );
                    });
            },
            (resp) => {
                setSpinner(false);
                resp != null &&
                    resp.text().then((t) =>
                        setAlert(
                            <Alert
                                variant="danger"
                                onClose={() => setAlert(<></>)}
                                dismissible
                            >
                                Error updating record: {t}
                            </Alert>
                        )
                    );
            },
            (error) => {
                setSpinner(false);
                setAlert(
                    <Alert variant="danger" onClose={() => setAlert(<></>)} dismissible>
                        Error updating record.
                    </Alert>
                );
            }
        );
    }
    let updateLicenseInfo = () => {
        setSpinner(true);
        let body = JSON.stringify({
            id: rememberedSelection,
            name: name,
            lastname: lastname,
            company: company,
            domain,
            email: email,
            backupemail: backupemail,
            country: country,
            state: state,
            license: ""
        });
        http.sendToServer(
            "POST",
            "/api/updatelicense",
            null,
            body,
            (resp) => {
                resp
                    .json()
                    .then((js) => {
                        setSpinner(false);
                        setAlert(
                            <Alert
                                variant="success"
                                onClose={() => setAlert(<></>)}
                                dismissible
                            >
                                Record updated
                            </Alert>
                        );
                        getLicenseInfo()
                    })
                    .catch((error) => {
                        setSpinner(false);
                        setAlert(
                            <Alert
                                variant="danger"
                                onClose={() => setAlert(<></>)}
                                dismissible
                            >
                                Error updating record: {error.message}
                            </Alert>
                        );
                    });
            },
            (resp) => {
                setSpinner(false);
                resp != null &&
                    resp.text().then((t) =>
                        setAlert(
                            <Alert
                                variant="danger"
                                onClose={() => setAlert(<></>)}
                                dismissible
                            >
                                Error updating record: {t}
                            </Alert>
                        )
                    );
            },
            (error) => {
                setSpinner(false);
                setAlert(
                    <Alert variant="danger" onClose={() => setAlert(<></>)} dismissible>
                        Error updating record.
                    </Alert>
                );
            }
        );
    };

    let updateUpdates = (id) => {
        let row = null;

        licenses.forEach((l) => {
            if (l["id"] === id) {
                row = l;
            }
        });
        if (row == null)
            return
        setName(row["name"]);
        setLastname(row["lastname"]);
        setCompany(row["company"]);
        setDomain(row["domain"]);
        setEmail(row["email"]);
        setBackupemail(row["backupemail"]);
        setCountry(row["country"]);
        setState(row["state"]);
        setLicense(row["license"]);

    }
    let onEdit = (id) => {
        return (e) => {
            // setSDRef.current(id)
            setRememberedSelection(id);
            updateUpdates(id)
        };
    };


    let onToggleActive = (id, active:boolean) => {
        return e => {
            updateActiveInfo (id, active)
        }
    }

    let columns = [
        {
            dataField: "id",
            text: "id",
            hidden: true,
        },
        {
            dataField: "company",
            text: "Company:",
            sort: true,
        },
        {
            dataField: "domain",
            text: "Domain:",
            sort: true,
        },
        {
            dataField: "name",
            text: "First name",
            sort: true,
        },
        {
            dataField: "lastname",
            text: "Last name:",
            sort: true,
        },


        {
            dataField: "email",
            text: "Email:",
            sort: true,
        },
        {
            dataField: "backupemail",
            text: "Backup email:",
            sort: true,
        },
        {
            dataField: "country",
            text: "Country:",
            sort: true,
        },
        {
            dataField: "state",
            text: "State:",
            sort: true,
        },
        {
            dataField: "license",
            text: "License:",
            hidden: true,
        },
        {
            text: "Edit",
            dataField: "edit",
            isDummyField: true,
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return (
                    <i
                        className="fas fa-edit ablue"
                        aria-label={"edit" + rowIndex}
                        id={"edit" + rowIndex}
                        onClick={onEdit(row["id"])}
                        role="button"
                    ></i>
                );
            },
            //formatExtraData: { hoverIdx: this.state.hoverIdx },
            headerStyle: { width: "50px" },
            style: { height: "30px" },
            align: "center",
        },
        {
            text: "Active",
            dataField: "active",
            isDummyField: true,
            sort: true,
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return (
                    <i
                        style={{ fontSize: "1.2em" }}
                        className= { row["active"] ?  "mt-1 fas fa-toggle-on ablue" : "mt-1 fas fa-toggle-off ablue"}
                        aria-label={"delete" + rowIndex}
                        id={"delete" + rowIndex}
                        onClick={onToggleActive(row["id"], !row["active"])}
                        role="button"
                    ></i>
                );
            },
            //formatExtraData: { hoverIdx: this.state.hoverIdx },
            headerStyle: { width: "90px" },
            style: { height: "30px" },
            align: "center",
        },
    ];

    // add useEffect to fetch data here
    useEffect(() => {
        getLicenseInfo();
    }, []);
    let getLicenseInfo = () => {
        setSpinner(true);

        http.sendToServer(
            "GET",
            "/api/getlicenses",
            null,
            "",
            (resp) => {
                resp
                    .json()
                    .then((js) => {
                        setLicenses(js);
                        setSpinner(false);
                    })
                    .catch((error) => {
                        setSpinner(false);
                        setAlert(
                            <Alert
                                variant="danger"
                                onClose={() => setAlert(<></>)}
                                dismissible
                            >
                                Error getting licenses: {error.message}
                            </Alert>
                        );
                    });
            },
            (resp) => {
                setSpinner(false);
                resp != null &&
                    resp.text().then((t) =>
                        setAlert(
                            <Alert
                                variant="danger"
                                onClose={() => setAlert(<></>)}
                                dismissible
                            >
                                Error getting licenses: {t}
                            </Alert>
                        )
                    );
            },
            (error) => {
                setSpinner(false);
                setAlert(
                    <Alert variant="danger" onClose={() => setAlert(<></>)} dismissible>
                        Error getting licenses.
                    </Alert>
                );
            }
        );
    };
    let selectRow = {
        mode: "radio",
        //clickToSelect: true,
        style: { backgroundColor: "rgba(0, 151, 206, 0.3)" },
        selected: [rememberedSelection],
        onSelect: (row, isSelect, rowIndex, e) => {
            setRememberedSelection(row["id"]);
            updateUpdates(row["id"]);
        },
    };
    let handleSubmit = (event) => {
        if (form.current == null) {
            return false;
        }

        if (form.current.reportValidity() === false) {
            event.preventDefault();
            setValidated(true);
            return false;
        }
        event.preventDefault();
        setValidated(false);
        event.stopPropagation();
        updateLicenseInfo();
        return false;
    };
    const defaultSorted = [{
        dataField: 'company', // name of the dataField on which you want to implement default sorting
        order: 'asc' // or 'desc' for descending order
      }];
    let editForm = () => {
        if (rememberedSelection === "")
            return <></>
        let row = null;

        licenses.forEach((l) => {
            if (l["id"] === rememberedSelection) {
                row = l;
            }
        });
        let license = row && row["license"];
        const copyText = async () => {
            try {
                await navigator.clipboard.writeText(license ? license : "");

                // Set isCopied to true after successfully copying to the clipboard
                setIsCopied(true);

                // Optionally: Reset the isCopied state back to false after a certain duration
                setTimeout(() => setIsCopied(false), 2000); // Reset after 2 seconds
            } catch (err) {
                console.error("Failed to copy text: ", err);
            }
        };
        return (
            <div>
                <hr />
                <div className="text-left">
                    <div>The license code:</div>
                    <Row className="my-3">
                        <Col
                            className="ml-3  p-2"
                            style={{
                                overflowX: "auto", // add a horizontal scrollbar if needed
                                maxWidth: "100%", // or another value to limit the width to its parent
                                fontSize: "0.8em",
                                border: "1px solid gray",
                            }}
                        >
                            {license}
                        </Col>
                        <Col xs="auto" className="py-2 mr-3">
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip id={`tooltip-top`}>
                                        {isCopied ? "Copied" : "Copy"}
                                    </Tooltip>
                                }
                            >
                                <i
                                    className="fa-solid fa-copy blue"
                                    onClick={copyText}
                                    style={{ fontSize: "1.6em" }}
                                ></i>
                            </OverlayTrigger>
                        </Col>
                    </Row>
                </div>
                <Form onSubmit={handleSubmit} ref={form} noValidate validated={validated}>
                    <Row>
                        <Col xs="auto">
                            <Form.Group className="mb-3 text-left" controlId="companyname">
                                <Form.Label>Customer company name:</Form.Label>
                                <Form.Control
                                    size="sm"
                                    type="text"
                                    required
                                    placeholder="Human readable name"
                                    style={{ width: "40em" }}
                                    pattern=".+"
                                    value={company}
                                    onChange={(e) => {
                                        setCompany(e.target.value);
                                    }}
                                />
                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">
                                    Enter company name
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>

                        <Col xs="auto">
                            <Form.Group className="mb-3 text-left" controlId="domain">
                                <Form.Label>Domain:</Form.Label>
                                <Form.Control
                                    size="sm"
                                    type="text"
                                    required
                                    placeholder="company.com"
                                    style={{ width: "40em" }}
                                    pattern=".+"
                                    value={domain}
                                    onChange={(e) => {
                                        setDomain(e.target.value);
                                    }}
                                />
                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">
                                    Enter customer domain
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="auto">
                            <Form.Group className="mb-3 text-left" controlId="name">
                                <Form.Label>Contact First name:</Form.Label>
                                <Form.Control
                                    size="sm"
                                    type="text"
                                    required
                                    placeholder="First name"
                                    style={{ width: "40em" }}
                                    pattern=".+"
                                    value={name}
                                    onChange={(e) => {
                                        setName(e.target.value);
                                    }}
                                />
                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">
                                    Enter first name
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col xs="auto">
                            <Form.Group className="mb-3 text-left" controlId="lastname">
                                <Form.Label>Contact Last name:</Form.Label>
                                <Form.Control
                                    size="sm"
                                    type="text"
                                    required
                                    placeholder="Last name"
                                    style={{ width: "40em" }}
                                    pattern=".+"
                                    value={lastname}
                                    onChange={(e) => {
                                        setLastname(e.target.value);
                                    }}
                                />
                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">
                                    Enter last name
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="auto">
                            <Form.Group className="mb-3 text-left" controlId="email">
                                <Form.Label>Contact email:</Form.Label>
                                <Form.Control
                                    size="sm"
                                    type="text"
                                    required
                                    placeholder="Contact name"
                                    style={{ width: "40em" }}
                                    pattern=".+"
                                    value={email}
                                    onChange={(e) => {
                                        setEmail(e.target.value);
                                    }}
                                />
                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">
                                    Enter contact email
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col xs="auto">
                            <Form.Group className="mb-3 text-left" controlId="backupemail">
                                <Form.Label>Backup email:</Form.Label>
                                <Form.Control
                                    size="sm"
                                    type="text"
                                    required
                                    placeholder="Backup email"
                                    style={{ width: "40em" }}
                                    pattern=".+"
                                    value={backupemail}
                                    onChange={(e) => {
                                        setBackupemail(e.target.value);
                                    }}
                                />
                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">
                                    Enter backup email
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="auto">
                            <Form.Group className="mb-3 text-left" controlId="country">
                                <Form.Label>Country:</Form.Label>
                                <Form.Control
                                    size="sm"
                                    type="text"
                                    required
                                    placeholder="USA, Canada, etc."
                                    style={{ width: "40em" }}
                                    pattern=".+"
                                    value={country}
                                    onChange={(e) => {
                                        setCountry(e.target.value);
                                    }}
                                />
                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">
                                    Enter country
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>

                        <Col xs="auto">
                            <Form.Group className="mb-3 text-left" controlId="state">
                                <Form.Label>State/Province:</Form.Label>
                                <Form.Control
                                    size="sm"
                                    type="text"
                                    required
                                    placeholder="CA, NY, etc."
                                    style={{ width: "40em" }}
                                    pattern=".+"
                                    value={state}
                                    onChange={(e) => {
                                        setState(e.target.value);
                                    }}
                                />
                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">
                                    Enter state/province
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                    <div className="text-left w-100">
                        <Button variant="dymium" size="sm" className="mt-4" type="submit">
                            Update
                        </Button>
                    </div>
                </Form>

            </div>
        );
    };

    return (
        <div className="p-4">
            <ToolkitProvider
                bootstrap4
                keyField="id"
                data={licenses}
                columns={columns}
                search
            >
                {(props) => {
                    return (
                        <div className="text-left">
                            {alert}
                            <div className="d-flex">
                                <h5>Edit Licenses {spinner &&  <Spinner size="sm" variant="dymium" animation="border" />}</h5>

                                <div style={{ marginLeft: "auto" }}>
                                    <SearchBar size="sm" {...props.searchProps} />
                                    <ClearSearchButton {...props.searchProps} />
                                    <i
                                        onClick={getLicenseInfo}
                                        className="fa fa-refresh blue"
                                        style={{ position: "relative", top: "2px" }}
                                        aria-hidden="true"
                                    ></i>
                                </div>
                            </div>
                            <div className="d-block">
                                <BootstrapTable
                                    id="scaledtable"
                                    condensed
                                    defaultSorted={defaultSorted}
                                    selectRow={selectRow}
                                    striped
                                    bootstrap4
                                    bordered={false}
                                    pagination={paginationFactory()}
                                    {...props.baseProps}
                                />
                            </div>
                        </div>
                    );
                }}
            </ToolkitProvider>
            {editForm()}
        </div>
    );
};

const AuthenticatedApp = () => {
    useEffect(() => { }, []);
    return (
        <>
            <Auth />
            <Menu />

            <Row style={{ height: "100vh" }} className="p-0 m-0">
                <Col xs className="p-0 spider" style={{ overflowX: "scroll" }}>
                    <Tabs id="licenses" unmountOnExit={true} className="text-left">
                        <Tab eventKey="license" title="Generate License" className="">
                            <License />
                        </Tab>
                        <Tab eventKey="review" title="Review Licenses" className="">
                            <Licenses />
                        </Tab>
                    </Tabs>
                </Col>
            </Row>
        </>
    );
};

export default AuthenticatedApp;
