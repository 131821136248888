import React from 'react';
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import '../App.scss';

function decodeBase64Url(str: string): string {
    // Replace non-url compatible chars with base64 standard chars
    str = str.replace(/-/g, '+').replace(/_/g, '/');

    // Decode using browser's built-in function
    const decodedString = atob(str);
    
    return decodedString;
}

function extractPayloadFromJWT(): any {
    let token = window.sessionStorage.getItem("Session");
    const base64Payload = token?.split('.')[1];
    if (!base64Payload) {
        return {};
    }

    const payload = JSON.parse(decodeBase64Url(base64Payload));
    return payload;
}


function Menu() {
    let getName = () => {
        let payload =  extractPayloadFromJWT()
        return payload["name"]
    }
        return (
            <div className="w-100 text-center deepestblue">
                <Navbar id="navb"
                    className="p-0 m-0"
                    collapseOnSelect expand="lg" variant="light"  >
                    <Navbar.Brand className="text-left p-0 m-0 mr-2" >

                    <Nav.Link className="p-1 m-0" style={{width: '48px'}} href="/">
                            <img src="/logo.svg" style={{width: '100%'}} alt="logo" />
                        </Nav.Link >

                    </Navbar.Brand>
                    <Navbar.Brand className="text-left p-0 m-0 mr-2" >
                        <Nav.Link className="p-0 m-0 ml-1" style={{width: '140px'}} href="/">
                            <img src="/brand.svg" style={{width: '100%', marginTop: '-4px'}} alt="logo" />
                        </Nav.Link >
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />

                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="mx-auto " >
                           <h3 className="mr-5 " style={{color: 'white', textShadow: '1px 1px 0 rgb(58 58 58 / 67%'}}>Licensing Server</h3>
                        </Nav>
                        <Nav.Link className=" p-0 m-0 ml-1 pr-4 uline" style={{ color: "white"}} href="/app/logout">
                           Logout {getName()}
                        </Nav.Link >
                    </Navbar.Collapse>
                </Navbar>

            </div>
        )
};

export default Menu