import React, { useEffect } from 'react';
import { TweenLite, Circ } from "gsap";

class Circle {
  pos: {}
  radius:number
  color: string
  ctx: any
  active: boolean
  constructor(ctx:any, pos:{}, rad:number, color:string) {
    this.pos=pos; 
    this.radius=rad;
    this.color=color
    this.ctx = ctx
    this.active=false
  }
  draw () {
    if (!this.active) return;
    this.ctx.beginPath();
    this.ctx.arc(this.pos["x"], this.pos["y"], this.radius, 0, 2 * Math.PI, false);
    this.ctx.fillStyle = 'rgba(156,217,249,' + this.active + ')';
    this.ctx.fill();
  };
}  
let width, height, canvas, ctx, points, target, animateHeader = true;

function Backdrop() {
  
    // Similar to componentDidMount and componentDidUpdate:
    useEffect(() => {
      canvas = document.getElementById('anicanvas');
      initHeader();
      initAnimation();
      addListeners();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    // Main
  

    function initHeader() {
      width = window.innerWidth;
      height = window.innerHeight;
      target = { x: width / 2, y: height / 2 };
  
  
    
      canvas.width = width;
      canvas.height = height;
      ctx = canvas.getContext('2d');
  
      // create points
      points = [];
      for (let x = 0; x < width; x = x + width / 5) {
        for (let y = 0; y < height; y = y + height / 5) {
          let px = x + Math.random() * width / 5;
          let py = y + Math.random() * height / 5;
          let p = { x: px, originX: px, y: py, originY: py };
          points.push(p);
        }
      }
  
      // for each point find the 5 closest points
      for (let i = 0; i < points.length; i++) {
        let closest:any = [];
        let p1 = points[i];
        for (let j = 0; j < points.length; j++) {
          let p2 = points[j]
          if (!(p1 === p2)) {
            let placed = false;
            for (let k = 0; k < 5; k++) {
              if (!placed) {
                if (closest[k] === undefined) {
                  closest[k] = p2;
                  placed = true;
                }
              }
            }
  
            for (let k = 0; k < 5; k++) {
              if (!placed) {
                if (getDistance(p1, p2) < getDistance(p1, closest[k])) {
                  closest[k] = p2;
                  placed = true;
                }
              }
            }
          }
        }
        p1.closest = closest;
      }
  
      // assign a circle to each point
      for (let i in points) {
        let c = new Circle(ctx, points[i], 4 + Math.random() * 4, 'rgba(255,255,255,0.3)');
        points[i].circle = c;
      }
    }
  
    // Event handling
    function addListeners() {
  
      //window.addEventListener('scroll', scrollCheck);
      window.addEventListener('resize', resize);
    }
  
    function resize() {
      let width = window.innerWidth;
      let height = window.innerHeight;
      //largeHeader.style.height = height + 'px';
      canvas.width = width;
      canvas.height = height;
      animateHeader = true
      //alert(width + ", " + height)
      initHeader()
      initAnimation()
    }
  
    // animation
    function initAnimation() {
      animate();
      for (let i in points) {
        shiftPoint(points[i]);
      }
    }
  
    function animate() {
      if (animateHeader) {
        ctx.clearRect(0, 0, width, height);
        for (let i in points) {
          // detect points in range
          if (Math.abs(getDistance(target, points[i])) < 4000) {
            points[i].active = 0.3;
            points[i].circle.active = 0.6;
          } else if (Math.abs(getDistance(target, points[i])) < 20000) {
            points[i].active = 0.1;
            points[i].circle.active = 0.3;
          } else if (Math.abs(getDistance(target, points[i])) < 40000) {
            points[i].active = 0.2;
            points[i].circle.active = 0.3;
          } else {
            points[i].active = 0.2;
            points[i].circle.active = 0.3;
          }
  
          drawLines(points[i]);
          points[i].circle.draw();
        }
      }
      requestAnimationFrame(animate);
    }
  
    function shiftPoint(p) {
      TweenLite.to(p, 1 + 1 * Math.random(), {
        x: p.originX - 50 + Math.random() * 100,
        y: p.originY - 50 + Math.random() * 100, ease: Circ.easeInOut,
        onComplete: function () {
          shiftPoint(p);
        }
      });
    }
  
    // Canvas manipulation
    function drawLines(p) {
      if (!p.active) return;
      for (let i in p.closest) {
        ctx.beginPath();
        ctx.moveTo(p.x, p.y);
        ctx.lineTo(p.closest[i].x, p.closest[i].y);
        ctx.strokeStyle = 'rgba(156,217,249,' + p.active + ')';
        ctx.stroke();
      }
    }
  
  
    // Util
    function getDistance(p1, p2) {
      return Math.pow(p1.x - p2.x, 2) + Math.pow(p1.y - p2.y, 2);
    }
  
  
    return (
        <canvas style={{ position: 'relative', top: '0px', left: '0px' }} id="anicanvas">
  
        </canvas>
    )
  }
  
  export default Backdrop;