import React from 'react';
import Backdrop from "./Backdrop"

function LandingPage() {  

  return (
    <div>
      <Backdrop />
      <div className="text-center" style={{
        position: 'absolute', top: '0px', left: '0px',
        width: '100%', height: '100vh'
      }}>
  
        <div id="loginbox">
          <div className="text-center w-100 d-flex align-items-center justify-content-center pt-2" >
            <div id="loginbox">
              <img alt="" style={{ position: 'relative', top: '50%', marginTop: '12em' }} className="text-center mb-5 svgshadow" src="/brand.svg"></img>
              <h4 className="logoheader ">This computer system is intended only for Dymium employees</h4>

              <div className="mt-5">
                <a
                  className=" dymium-button-link"

                  href="/api/login"

                  rel="noopener noreferrer"
                >
                  Click to login!
                </a>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  )
}

export default LandingPage;